//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "articleCard",
  props: {
    id: Number,
    title: String,
    announce: String,
    image: {
      type: [String, Number],
    },
    slug: {
      type: String,
    },
    category: {
      type: String,
    },
    isFromHome: {
      type: Boolean,
      default() {
        return false;
      },
    },
    count: {
      type: Number,
      default: 0,
    }
  },

};
